import React, { useState, useEffect } from 'react';
import '../css/PersonalInformationModal.css';
import { fetchPersonalInformation, updatePersonalInformation, fetchSavedTemplates, saveTemplate, deleteTemplate } from '../utils/PersonalInformationUtils';

function PersonalInformationModal({ isOpen, onClose }) {
  const [doctorInfo, setDoctorInfo] = useState('');
  const [practiceInfo, setPracticeInfo] = useState('');
  const [templateInfo, setTemplateInfo] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // New state for templates
  const [savedTemplates, setSavedTemplates] = useState([]);
  const [isStoringTemplate, setIsStoringTemplate] = useState(false);
  const [newTemplateName, setNewTemplateName] = useState('');

  // Add new state for delete confirmation
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [selectedTemplateId, setSelectedTemplateId] = useState(null);

  useEffect(() => {
    if (isOpen) {
      loadPersonalInformation();
      loadSavedTemplates();
    }
  }, [isOpen]);

  const loadSavedTemplates = async () => {
    try {
      const data = await fetchSavedTemplates();
      if (data.templates) {
        setSavedTemplates(data.templates);
      }
    } catch (err) {
      setError('Failed to load templates.');
    }
  };

  // Modify handleTemplateSelect to track selected template
  const handleTemplateSelect = (event) => {
    const value = event.target.value;
    if (value === 'empty') {
      setTemplateInfo('');
      setSelectedTemplateId(null);
      return;
    }

    const selectedTemplate = savedTemplates.find(
      template => template.id === parseInt(value)
    );
    if (selectedTemplate) {
      setTemplateInfo(selectedTemplate.template_data);
      setSelectedTemplateId(parseInt(value));
    }
  };

  const handleStoreTemplate = async () => {
    if (!newTemplateName.trim()) {
      setError('Template name is required');
      return;
    }

    try {
      await saveTemplate(newTemplateName, templateInfo);
      await loadSavedTemplates();
      setIsStoringTemplate(false);
      setNewTemplateName('');
    } catch (err) {
      setError('Failed to save template');
    }
  };

  const loadPersonalInformation = async () => {
    setLoading(true);
    setError(null);
    try {
      const data = await fetchPersonalInformation();
      setDoctorInfo(data.user_info || '');
      setPracticeInfo(data.practice_info || '');
      setTemplateInfo(data.template_info || '');
    } catch (err) {
      setError('Failed to load personal information.');
    } finally {
      setLoading(false);
    }
  };


  const handleSave = async () => {
    setLoading(true);
    setError(null);
    try {
      await updatePersonalInformation(doctorInfo, practiceInfo, templateInfo); // Include templateInfo here
      onClose(); // Close modal after successful save
    } catch (err) {
      setError('Failed to save personal information.');
    } finally {
      setLoading(false);
    }
  };


  if (!isOpen) return null;

  return (
    <div className="personal-info-modal-overlay">
      <div className="personal-info-modal-content">
        <h2>Personal Information</h2>

        {error && <p className="error-message">{error}</p>}

        <div className="personal-info-input-group">
          <div className="doctor-info">
            <label className="personal-info-label" htmlFor="doctor-info-input">
              Doctor Information
            </label>
            <textarea
              id="doctor-info-input"
              className="personal-info-textarea doctor-info-textarea"
              value={doctorInfo}
              onChange={(e) => setDoctorInfo(e.target.value)}
              placeholder="Dr. Jane Smith"
            />
          </div>

          <div className="practice-info">
            <label className="personal-info-label" htmlFor="practice-info-input">
              Practice Information
            </label>
            <textarea
              id="practice-info-input"
              className="personal-info-textarea practice-info-textarea"
              value={practiceInfo}
              onChange={(e) => setPracticeInfo(e.target.value)}
              placeholder="456 High St, London, UK"
            />
          </div>

          <div className="template-info">
            <label className="personal-info-label" htmlFor="template-info-input">
              Template Information
            </label>

            <textarea
              id="template-info-input"
              className="personal-info-textarea template-info-textarea"
              value={templateInfo}
              onChange={(e) => setTemplateInfo(e.target.value)}
              placeholder="SOAP note..."
            />

            <div className="template-controls">
              <select
                id="template-select"
                onChange={handleTemplateSelect}
                className="template-select"
                value={selectedTemplateId || ''}
              >
                <option value="">Select a template</option>
                <option value="empty">Empty template</option>
                {savedTemplates.map(template => (
                  <option key={template.id} value={template.id}>
                    {template.name}
                  </option>
                ))}
              </select>

              <button
                className="store-template-button"
                onClick={() => setIsStoringTemplate(true)}
              >
                Store Template
              </button>

              {selectedTemplateId && (
                <button
                  className="delete-template-button"
                  onClick={() => setShowDeleteConfirm(true)}
                >
                  Delete Template
                </button>
              )}
            </div>
          </div>

          {isStoringTemplate && (
            <div className="store-template-modal">
              <input
                type="text"
                value={newTemplateName}
                onChange={(e) => setNewTemplateName(e.target.value)}
                placeholder="Enter template name"
              />
              <div className="store-template-buttons">
                <button onClick={handleStoreTemplate}>Save</button>
                <button onClick={() => {
                  setIsStoringTemplate(false);
                  setNewTemplateName('');
                }}>Cancel</button>
              </div>
            </div>
          )}

          {showDeleteConfirm && (
            <div className="store-template-modal">
              <p>Are you sure you want to delete this template?</p>
              <div className="store-template-buttons">
                <button onClick={async () => {
                  try {
                    await deleteTemplate(selectedTemplateId);
                    await loadSavedTemplates();
                    setSelectedTemplateId(null);
                    setTemplateInfo('');
                    setShowDeleteConfirm(false);
                  } catch (err) {
                    setError('Failed to delete template');
                  }
                }}>Yes, Delete</button>
                <button onClick={() => setShowDeleteConfirm(false)}>Cancel</button>
              </div>
            </div>
          )}

        </div>

        <div className="personal-info-modal-buttons">
          <button className="personal-info-save-button" onClick={handleSave} disabled={loading}>
            {loading ? 'Saving...' : 'Save'}
          </button>
          <button className="personal-info-close-button" onClick={onClose}>
            Close
          </button>
        </div>
      </div>
    </div>
  );
}

export default PersonalInformationModal;
